.ad-filter {
	margin-bottom: 24px;
}
.ad-filter-top {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.btn-toggle {
	display: flex;
	align-items: center;
	padding: 0 16px;
	height: 32px;
	line-height: 32px;
	border-radius: 4px;
	border: 1px solid #E6E6E6;
	cursor: pointer;
}
.icon-toggle {
	margin-left: 10px;
	color: #ccc;
	transform: rotate(180deg);
	&.bot {
		transform: rotate(0);
	}
}

.form {
	height: auto;
	overflow: hidden;
	&.hide {
		height: 0;
	}
}
.form-item {
	padding: 20px 0 10px;
	display: flex;
	border-bottom: 1px solid #EDEDED;
}
.form-label {
	margin-right: 36px;
	width: 200px;
}
.form-value {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	flex: 1;
}
.form-flex {
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	.cur-position {
		cursor: pointer;
	}
	.btn-clear {
		margin-left: 10px;
		font-size: 10px;
	}
}
.suggestion-list {
	position: absolute;
	top: 33px;
	left: 0;
	z-index: 2;
	width: 300px;
	padding: 12px 20px;
	background-color: #fff;
	border: 1px solid #f5f5f5;
	.suggestion-item {
		margin-bottom: 16px;
		cursor: pointer;
		.suggestion-item-tit {
			margin-bottom: 6px;
			font-weight: bold;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
.text-location {
	margin-right: 50px;
	display: flex;
	align-items: center;
	color: #FF0032;
	cursor: pointer;
}
.icon-location {
	margin-right: 10px;
}
.text-other {
	cursor: pointer;
}
.icon-other {
	margin-left: 8px;
	color: #ccc;
	transform: rotate(180deg);
	&.bot {
		transform: rotate(0);
	}
}

.distance-item {
	margin-right: 50px;
	cursor: pointer;
}
.is-selected {
	color: #FF0032;
}

.city-wrap {
	width: 100%;
	overflow: hidden;
	height: auto;
	&.small {
		height: calc(32 * 1px);
	}
}
.brand-item {
	/* margin-right: 64px; */
	margin-bottom: 10px;
	display: flex;
	flex-wrap: wrap;
	.brand-item-l {
		margin-right: 48px;
		width: 100px;
		border-right: 1px solid #ccc;
	}
}
.district-list {
	flex: 1;
	display: flex;
	flex-wrap: wrap;
	.district-item {
		margin-right: 48px;
		margin-bottom: 10px;
	}
}
.btn-more {
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer;
	display: flex;
	align-items: center;
}
.brand-wrap {
	overflow: hidden;
	height: auto;
	&.small {
		height: calc(32 * 1px);
	}
}

.radio-item {
	margin-right: 40px;
}