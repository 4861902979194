.ad-page {
	position: relative;
}
.ad-page.is-paywait {
	padding-bottom: 88px;
}

.ad-page-crumb {
	margin: 24px;
	font-size: 16px;
}

.ad-page-cont {
	margin-bottom: 16px;
	padding: 24px;
	background-color: #fff;
	border: 1px solid #E6E6E6;
	border-radius: 8px;
}
.ad-page-tit {
	margin: 0 -24px 24px -24px;
	padding-left: 24px;
	padding-bottom: 16px;
	border-bottom: 1px solid #EDEDED;
	font-weight: bold;
	font-size: 20px;
}

.tabs {
	margin-bottom: 24px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #E6E6E6;
}
.tab-item {
	position: relative;
	margin-right: 4px;
	padding: 0 24px;
	border: 1px solid #E6E6E6;
	border-bottom: none;
	height: 45px;
	line-height: 45px;
	background-color: #FAFAFA;
	border-radius: 8px 8px 0px 0px;
	cursor: pointer;
}
.tab-item.is-selected {
	margin-bottom: -1px;
	height: 46px;
	background-color: #fff;
	font-weight: bold;
	color: #FF0032;
}