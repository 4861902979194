.crumb-list {
  margin: 16px 0;
  color: #999;
  font-size: 12px;
}
.crumb-parent {
  cursor: pointer;
}
.crumb-cur {
  color: #333;
  font-weight: bold;
}
