.profile {
	margin-bottom: 16px;
  position: relative;
  padding: 32px;
  height: 176px;
  background-color: #fff;
  border-radius: 8px;
  flex: 1;
}

.avatar {
  width: 112px;
  height: 112px;
  border-radius: 8px;
  background-color: #f5f5f5;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-avatar {
  font-size: 60px;
  color: #A1A6AD;
}

.main-info {
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.name {
	margin-top: 10px;
	margin-bottom: 16px;
	font-weight: bold;
	font-size: 24px;
}
.role-label {
  margin-left: 16px;
  padding: 0 8px;
  height: 21px;
  line-height: 18px;
  border-radius: 30px 30px 30px 30px;
  border: 1px solid #ccc;
  font-size: 12px;
  color: #666;
  font-weight: normal;
}
.excerpt {
	margin-bottom: 8px;
	line-height: 1.2;
  color: #999;
}

.btn-link {
  position: absolute;
  right: 24px;
  top: 16px;
  color: #999;
  display: flex;
  align-items: center;
  cursor: pointer;
  .icon-link {
    margin-left: 8px;
    font-size: 12px;
    color: #ccc;
		transform: rotate(-90deg);
  }
}