.mutli-item {
  display: flex;
  margin-bottom: 4px;
  .mutli-item-perant {
    position: relative;
    width: 100px;
    margin-right: 24px;
    &::after {
      position: absolute;
      right: 0;
      content: ' ';
      width: 1px;
      height: 20px;
      background-color: #EDEDED;
    }
  }
}
.list {
  overflow: hidden;
  height: auto;
  &.small {
    height: calc(32 * 2px);
  }
}
.item {
  margin-bottom: 10px;
  width: 88px;
  &.big {
    width: 116px;
  }
}