@font-face {
  font-family: 'ifont';  /* Project id 2657595 */
  src: url('//at.alicdn.com/t/font_2657595_2qz2gqkayln.woff2?t=1625726033293') format('woff2'),
       url('//at.alicdn.com/t/font_2657595_2qz2gqkayln.woff?t=1625726033293') format('woff'),
       url('//at.alicdn.com/t/font_2657595_2qz2gqkayln.ttf?t=1625726033293') format('truetype'),
       url('//at.alicdn.com/t/font_2657595_2qz2gqkayln.svg?t=1625726033293#ifont') format('svg');
}

.ifont {
  font-family: "ifont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-close-o:before {
  content: "\e6ce";
}

.i-download:before {
  content: "\e6cd";
}

.i-question:before {
  content: "\e6cc";
}

.i-location:before {
  content: "\e6cb";
}

.i-robot:before {
  content: "\e6ca";
}

.i-user:before {
  content: "\e6c9";
}

.i-weixin-mini:before {
  content: "\e6c8";
}

.i-tips:before {
  content: "\e6c6";
}

.i-arrow-r:before {
  content: "\e6c5";
}

.i-config:before {
  content: "\e6c3";
}

.i-android:before {
  content: "\e6c1";
}

.i-location-thin:before {
  content: "\e6c0";
}

