.modal-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-tit {
  margin: 24px 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
}
.modal-btn-confirm {
  margin: 48px 0;
  width: 160px;
  height: 40px;
  line-height: 40px;
  background-color: #FF0032;
  border-radius: 4px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  cursor: pointer;
}