.modal-cont {
  margin-top: -24px;
  .form-item {
    padding: 16px 0;
    display: flex;
    border-bottom: 1px solid #EDEDED;
  }
  .form-label {
    margin-right: 24px;
    width: 84px;
    font-weight: bold;
    text-align: right;
  }
  .form-value {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }
  .list {
    overflow: hidden;
    height: auto;
    &.small {
      height: calc(32 * 2px);
    }
  }
  .region-list {
    display: block;
    overflow: hidden;
    height: auto;
    &.small {
      height: calc(32 * 2px);
    }
  }
  .item {
    margin-bottom: 10px;
    width: 88px;
    &.big {
      width: 116px;
    }
  }
  
  .btn-more {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    .icon-other {
      color: #ccc;
    }
  }
}
.city-cont {
  height: 300px;
  overflow: auto;
}
.city-wrap {
  border: 1px solid #E6E6E6;
  border-radius: 8px 8px 0 0;
  .city-wrap-item {
    display: flex;
    &:not(:last-child) {
      border-bottom: 1px solid #E6E6E6;
    }
    &:first-child {
      .letter-label {
        border-radius: 8px 0px 0px 0px;
      }
    }
    &.is-selected {
      background-color: #E6E6E6;
      .letter-label {
        position: relative;
        background-color: #FF0032;
        color: #fff;
        &::before {
          position: absolute;
          right: -15px;
          top: 50%;
          transform: translateY(-50%);
          content: ' ';
          border: 8px solid transparent;
          border-left-color: #FF0032;
        }
      }
    }
    
    .letter-label {
      width: 72px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #F5F5F5;
      border-right: 1px solid #E6E6E6;
      font-size: 18px;
      font-weight: bold;
    }
    .city-list {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      padding: 16px 0 0 16px;
      .city-item {
        margin: 0 24px 16px 0;
        cursor: pointer;
        &.is-selected {
          color: #FF0032;
        }
      }
    }
  }
}

.modal-footer {
  margin: 0 -24px -24px;
  border-top: 1px solid #E6E6E6;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  .modal-footer-l {
    display: flex;
    align-items: flex-start;
    .modal-footer-l-inner {
      margin-right: 8px;
    }
    .modal-l-label {
      color: #999;
    }
    .modal-l-count {
      color: #333;
    }
    .btn-clear {
      font-size: 12px;
      color: #FF0032;
      cursor: pointer;
    }
  }
  .modal-footer-city-list {
    display: flex;
    flex-wrap: wrap;
    .city-item {
      margin: 0 8px 8px 0;
      padding: 4px 8px;
      height: 24px;
      border-radius: 4px;
      border: 1px solid #ccc;
      display: flex;
      align-items: center;
      cursor: pointer;
      .btn-del {
        margin-left: 4px;
        width: 12px;
        height: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon-del {
          font-size: 6px;
          color: #999;
        }
      }
    }
  }
  .btn-confirm {
    padding: 0 24px;
    height: 40px;
    background-color: #FF0032;
    border-radius: 4px;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
    border: none;
    cursor: pointer;
  }
}