.personal-home {
  padding: 16px 0;
}
.personal-home-tit {
  margin-bottom: 16px;
  font-size: 12px;
  font-weight: bold;
}
.personal-cont {
  margin: 16px 0;
  display: flex;
}
.personal-cont-l {
  flex: 1;
}
.personal-cont-r {
  margin-left: 16px;
  width: 544px;
  display: flex;
  flex-direction: column;
}