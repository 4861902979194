.modal-tit {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #E6E6E6;
  color: #666;
  .score {
    margin-right: 40px;
    font-size: 24px;
    color: #333;
  }
}
.modal-filter {
  margin-bottom: 16px;
}
.table-tit {
  margin-bottom: 16px;
}
.text-price-number {
  font-weight: bold;
  font-size: 16px;
}