.sign-in {
  /*position: relative;
  height: inherit;
  overflow: hidden;*/
}

.sign {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 56px 0 0;
  width: 462px;
  height: 488px;
  border-radius: 16px;
  background-color: #fff;
  transform: translate(25%, -50%);
}

.switch {
  margin-bottom: 48px;
  border-bottom: 1px solid #E6E6E6;
  cursor: pointer;
}

.tab {
  position: relative;
  margin-right: 64px;
  padding-bottom: 16px;
  font-size: 24px;
  color: #333;
}

.tab.active {
  color: #333;
}
.tab.active:after {
  position: absolute;
  left: 50%;
  bottom: 0;
  content: ' ';
  width: 48px;
  height: 4px;
  background-color: #ff0032;
  transform: translateX(-50%);
}

.btn-registe {
  margin-top: -40px;
  padding: 24px 0;
  font-size: 16px;
  color: #666;
  text-align: center;
  cursor: pointer;
}

.footer {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
}