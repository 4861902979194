.header-r {
  height: 64px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.avatar {
  margin-right: 16px;
  width: 34px;  
  height: 34px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f5f5f5;
}
.user-info {
  height: 34px;
}
.user-name {
  display: flex;
  align-items: center;
  height: 34px;
  color: #333; 
}
.is-first-page.is-home .user-name {
  color: #fff;
}
.user-popup .user-name {
  color: #333;
}
.china-name {
  font-size: 16px;
}
.role-label {
  margin-left: 10px;
}
.company {
  margin-top: 4px;
  font-size: 12px;
}
.label {
  color: #999;
}

.user-popup {
  font-size: 12px;
}
.info-list {
  margin-top: 10px;
}
.btn-sign-out {
  margin: 40px -16px -12px;
  height: 40px;
  line-height: 40px;
  background-color: #F5F5F5;
  border-radius: 0px 0px 3px 3px;
  color: #FF0032;
  cursor: pointer;
  text-align: center;
}
.btn-sign-out:hover {
  background-color: #eee;
}