.ant-menu-submenu-title {
  color: #666666;
}

.ant-menu {
	color: #666;
}

.ant-menu .menufont {
	font-size: 20px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fafafa !important;
  color: #333333 !important;
  font-weight: bold;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #FF0032 !important;
}

.ant-menu-submenu-title:hover,
.ant-menu-item:hover,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #333333 !important;
}

.ant-radio-input:focus+.ant-radio-inner, .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner,
.ant-radio-checked:after {
	border-color: #FF0032;
}
.ant-radio-checked .ant-radio-inner {
	border-color: #FF0032 !important;
}
.ant-radio-inner:after {
	background-color: #FF0032 !important;
}

.ant-modal-content {
	border-radius: 16px;
}
.ant-modal-header {
	border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}