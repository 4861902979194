.wrap {
  height: 100%;
}

.main {
  position: relative;
  margin-top: 72px;
  background-color: #f5f5f5;
  display: flex;
}

.content {
  margin: 0 16px;
  width: 100%;
}
.content-inner {

}