.header {
  position: relative;
  border-bottom: 1px solid #f5f5f5;
}

.close-icon {
  position: absolute;
  top: 18px;
  right: 16px;
}

.footer {
  border-top: 1px solid #f5f5f5;
}

.confirm-btn {
  border: none;
  border-radius: 4px;
  background-color: #ff0032;
  color: #fff;
}

.confirm-btn:hover {
  background-color: #ff0032;
  color: #fff;
}

.confirm-btn.is-cinema {
  background-color: #465870;
}
.confirm-btn.is-cinema:hover {
  opacity: 0.9;
}