.home {
}

.home-banner {
  position: relative;
  height: 100vh;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.home-banner-video {
  width: 100%;
}
.btn-arrow {
  position: absolute;
  left: 50%;
  bottom: 80px;
  width: 40px;
  height: 40px;
  background: url('https://cdn.remudooh.com/static/jtt-media-buyer-web/home/icon-arrow-bottom.png') center center no-repeat;
  transform: translateX(-50%);
  opacity: 0.6;
  cursor: pointer;
  transition: bottom 0.5s;
}
.btn-arrow:hover {
  bottom: 60px;
}

.home-cinema {
  min-height: 100vh;
  background-color: #fff;
}
.home-cinema-tit {
  padding: 100px 0 80px;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
}
.cinema-tabs {
  width: 1272px;
  margin: 0 auto 36px;
  display: flex;
  align-items: center;
}
.cinema-tab-item {
  width: 235px;
  height: 133px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 28px;
  line-height: 1.5;
  font-weight: 500;
  cursor: pointer;
}
.cinema-tab-item:hover {
  color: #FF0032;
  font-weight: bold;
}
.cinema-tab-item.is-selected {
  position: relative;
  transition: all 0.5s;
  color: #FF0032;
  font-weight: bold;
}
.cinema-tab-item:not(last-child) {
  margin-right: 24px;
}
.cinema-tab-item:nth-child(1) {
  background-color: #F2F2F2;
  border-top-left-radius: 8px;
  border-top-right-radius: 0;
  border-right: 2px solid #dbdbdb;
}
.cinema-tab-item:nth-child(1).is-selected {
  border-top-right-radius: 8px;
  background-color: rgba(255, 0, 50, .08);
  border-right-color: transparent;
}
.cinema-tab-item .cinema-tab-line {
  position: absolute;
  bottom: 0;
  width: 0;
  transition: width 0.5s;
}
.cinema-tab-item .cinema-tab-line:after {
  content: ' ';
  position: absolute;
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%);
  border: 10px solid transparent;
  border-top-color: #FF0032;
  opacity: 0;
  transition: opacity 0.5s;
}
.cinema-tab-item.is-selected .cinema-tab-line {
  width: 100%;
}
.cinema-tab-item.is-selected .cinema-tab-line:before {
  content: ' ';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  height: 2px;
  background-color: #FF0032;
}
.cinema-tab-item.is-selected .cinema-tab-line:after {
  opacity: 1;
}
.icon-tab-item {
  margin-bottom: 20px;
  width: 48px;
  height: 48px;
}

.cinema-conts {
  position: relative;
  padding: 60px 0;
  height: 562px;
  background-color: #F7F8FA;
}
.cinema-cont-item {
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  opacity: 1;
  width: 1272px;
  margin: 0 auto;
  display: flex;
  transition: opacity 0.5s;
}
.cinema-cont-left {
  flex: 1;
  padding-top: 80px;
}
.cinema-cont-text {
  margin-bottom: 28px;
  font-size: 20px;
  color: #666;
  line-height: 1.7;
}
.cinema-cont-item:nth-child(2) .cinema-cont-text,
.cinema-cont-item:nth-child(3) .cinema-cont-text,
.cinema-cont-item:nth-child(4) .cinema-cont-text {
  position: relative;
  padding-left: 10px;
}
.cinema-cont-item:nth-child(2) .cinema-cont-text:before,
.cinema-cont-item:nth-child(3) .cinema-cont-text:before,
.cinema-cont-item:nth-child(4) .cinema-cont-text:before {
  position: absolute;
  left: 0;
  top: 16px;
  content: ' ';
  width: 3px;
  height: 3px;
  background-color: #666;
  border-radius: 50%;
}
.cinema-cont-label {
  font-weight: bold;
  color: #333;
}
.cinema-cont-img {
  margin-left: 50px;
  width: 786px;
  height: 442px;
  border-radius: 16px;
  background: url('https://cdn.remudooh.com/static/jtt-media-buyer-web/home/pic-cinema-cont-1.png') center center no-repeat;
}
.cinema-cont-item:nth-child(4) .cinema-cont-img {
  background-image: url('https://cdn.remudooh.com/static/jtt-media-buyer-web/home/pic-cinema-cont-2.png');
}
.cinema-cont-item:nth-child(3) .cinema-cont-img {
  background-image: url('https://cdn.remudooh.com/static/jtt-media-buyer-web/home/pic-cinema-cont-3.png');
}
.cinema-cont-item:nth-child(2) .cinema-cont-img {
  background-image: url('https://cdn.remudooh.com/static/jtt-media-buyer-web/home/pic-cinema-cont-4.png');
}
.cinema-cont-item:nth-child(5) .cinema-cont-img {
  background-image: url('https://cdn.remudooh.com/static/jtt-media-buyer-web/home/pic-cinema-cont-5.png');
}
.cinema-cont-tit {
  margin-bottom: 40px;
  font-size: 24px;
  font-weight: bold;
}
.cinema-text-important {
  color: #FF0032;
}

.home-advantage {
  /*height: 1046px;*/
  overflow: hidden;
  background: url('https://cdn.remudooh.com/static/jtt-media-buyer-web/home/bg-advantage.png?v=3') center top no-repeat; 
}
.home-advantage-inner {
  position: relative;
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 1.5s ease-in-out 0s;
  opacity: 0;
  transform: translateY(400px);
}
.home-advantage-inner.is-in {
  opacity: 1;
  transform: translateY(0);
}
.home-advantage-tit {
  padding-bottom: 80px;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  line-height: 1.5;
}
.home-advantage-list {
  width: 1272px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.home-advantage-item {
  margin-right: 3px;
  position: relative;
  width: 315px;
  height: 634px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  cursor: pointer;
}
.home-advantage-img {
  width: 315px;
  height: 244px;
  background: url('https://cdn.remudooh.com/static/jtt-media-buyer-web/home/pic-advantage-1.png') center center no-repeat;
}
.home-advantage-item:nth-child(1) {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
.home-advantage-item:nth-child(2) {
  justify-content: space-between;
}
.home-advantage-item:nth-child(4) {
  justify-content: space-between;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.home-advantage-item:nth-child(1) .home-advantage-img {
  border-top-left-radius: 16px;
}
.home-advantage-item:nth-child(2) .home-advantage-img {
  background-image: url('https://cdn.remudooh.com/static/jtt-media-buyer-web/home/pic-advantage-2.png');
}
.home-advantage-item:nth-child(3) .home-advantage-img {
  background-image: url('https://cdn.remudooh.com/static/jtt-media-buyer-web/home/pic-advantage-3.png');
}
.home-advantage-item:nth-child(4) .home-advantage-img {
  background-image: url('https://cdn.remudooh.com/static/jtt-media-buyer-web/home/pic-advantage-4.png');
  border-bottom-right-radius: 16px;
}
.home-advantage-cont {
  padding: 32px 24px 0;
}
.home-advantage-item-tit {
  margin-bottom: 32px;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.5;
}
.home-advantage-text {
  position: relative;
  padding-left: 12px;
  margin-bottom: 20px;
  font-size: 16px;
  color: #666;
}
.home-advantage-text::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 0;
  width: 3px;
  height: 3px;
  background-color: #666;
  border-radius: 50%;
}

.home-scene {
  padding: 100px 0 60px;
  background-color: #fff;
}
.home-scene-inner {
  width: 1272px;
  margin: 0 auto;
}
.home-scene-tit {
  padding-bottom: 80px;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
}
.home-scene-tabs {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-scene-tab {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 24px;
  width: 219px;
  height: 158px;
  border: 1px solid #E6E8EC;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  font-size: 20px;
  color: #666;
  cursor: pointer;
}
.home-scene-tab:last-child {
  margin-right: 0;
}
.home-scene-tab:hover {
  background-color: #fff;
  border-color: #FF0032;
  font-weight: bold;
  color: #333;
}
.home-scene-tab.is-selected {
  background-color: #fff;
  border-color: #FF0032;
  box-shadow: 1px 1px 12px 0px rgba(220, 0, 43, 0.17);
  font-weight: bold;
  color: #333;
}
.home-scene-tab.is-selected:before {
  content: ' ';
  position: absolute;
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: #FF0032;
}
.home-scene-tab.is-selected:after {
  content: ' ';
  position: absolute;
  left: calc(50%);
  bottom: -18px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-top-color: #fff;
}
.home-scene-tab-icon {
  margin-bottom: 16px;
  color: #C0C1CC;
  font-size: 48px;
}
.home-scene-tab:hover .home-scene-tab-icon,
.home-scene-tab.is-selected .home-scene-tab-icon {
  color: #FF0032;
  font-weight: normal;
}

.home-scene-cont {
  overflow: hidden;
  position: relative;
  height: 520px;
}
.home-scene-cont-item {
  position: absolute;
  left: 0;
  top: 0;
  width: 1272px;
  height: 100%;
  display: flex;
  opacity: 1;
  z-index: 1;
  transition: opacity 500ms ease 0s, visibility 500ms ease 0s;
}
.home-scene-cont-item.is-hide {
  opacity: 0;
  z-index: 0;
}
.home-scene-pic {
  margin: 40px 70px 40px 40px;
  width: 656px;
  height: 440px;
  background: #F6F6F6 url('https://cdn.remudooh.com/static/jtt-media-buyer-web/home/pic-scene-1.png?v=2');
  box-shadow: 0px 6px 32px 0px rgba(10, 26, 56, 0.3);
  border-radius: 24px;
}
.home-scene-cont-item:nth-child(2) .home-scene-pic {
  background-image: url('https://cdn.remudooh.com/static/jtt-media-buyer-web/home/pic-scene-2.png');
}
.home-scene-cont-item:nth-child(3) .home-scene-pic {
  background-image: url('https://cdn.remudooh.com/static/jtt-media-buyer-web/home/pic-scene-3.png');
}
.home-scene-cont-item:nth-child(4) .home-scene-pic {
  background-image: url('https://cdn.remudooh.com/static/jtt-media-buyer-web/home/pic-scene-4.png');
}
.home-scene-cont-r {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.home-scene-cont-tit {
  margin-bottom: 40px;
  font-size: 28px;
  font-weight: bold;
}
.home-scene-cont-text {
  margin-bottom: 64px;
  font-size: 18px;
  color: #666;
  line-height: 1.7;
}
.home-scene-cont-link {
  /*position: absolute;
  left: 0;
  bottom: 120px;*/
  width: 200px;
  height: 64px;
  line-height: 64px;
  background-color: #FF0032;
  border-radius: 8px;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}
.home-scene-cont-link:hover {
  background-color: #E6002E;
}

.home-contact {
  padding-top: 80px;
  background: url('https://cdn.remudooh.com/static/jtt-media-buyer-web/home/bg-contact.png') center top no-repeat;
}
.home-contact-inner {
  width: 1272px;
  margin: 0 auto 80px;
  display: flex;
  justify-content: space-between;
}
.home-contact-left {
}
.home-contact-tit {
  margin-bottom: 40px;
  font-size: 28px;
  font-weight: bold;
}
.home-contact-text {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  font-size: 16px;
}
.home-contact-text .home-contact-icon {
  margin-right: 16px;
  font-size: 20px;
  color: #666;
}
.home-contact-value {
  color: #666;
}
.home-contact-text a:hover {
  color: #FF0032;
}
.home-contact-r {
  display: flex;
  align-items: center;
}
.home-contact-minicode {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.5;
  font-size: 14px;
  color: #666666;
}
.home-contact-minicode:not(last-child) {
  margin-right: 24px;
}
.home-contact-minicode-img {
  margin-bottom: 16px;
  width: 138px;
  height: 138px;
  border-radius: 8px;
  border: 2px solid rgba(255, 0, 50, 0.2);
  background: #fff url('https://cdn.remudooh.com/static/jtt-media-buyer-web/home/pic-gzh.jpg') center center no-repeat;
}
.home-contact-minicode:nth-child(1) .home-contact-minicode-img {
  background-size: 100%;
}
.home-contact-minicode:nth-child(2) .home-contact-minicode-img {
  background-image: url('../../assets/images/common/mini-code.jpg');
  background-size: 90%;
}

.footer {
  border-top: 2px solid rgba(255, 255, 255, 0.6);
  padding: 24px 0;
  text-align: center;
  color: #666;
}
.footer a {
	color: #666;
}