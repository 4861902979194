.company-home {
  padding: 16px 0;
}
.company-home-tit {
  margin-bottom: 16px;
  font-size: 12px;
  font-weight: bold;
}
.company-cont {
  margin: 16px 0;
  display: flex;
}
.company-cont-t {
  display: flex;
}
.company-cont-l {
  flex: 1.8;
}
.company-cont-r {
  margin-left: 16px;
  flex: 1;
}