.staff {
  margin-bottom: 16px;
  background-color: #FFFFFF;
  border-radius: 8px;
  height: 176px;
}

.title {
  padding: 24px 24px 16px;
  border-bottom: 1px solid #EDEDED;
  font-size: 20px;
  font-weight: bold;
}

.staff-list {
  margin: 24px 20%;
  display: flex;
  justify-content: space-around;
  color: #999;
}

.staff-list span {
  font-size: 24px;
  font-weight: bold;
}