/* 广告素材呈现效果 */
.effect-modal-tips {
  font-size: 16px;
  &:last-child {
    margin-bottom: 40px;
  }
}
.effect-modal-tit-tips {
  font-size: 14px;
  color: #666;
}
.effect-modal-cont {
  margin: 24px 0;
  background-color: #F5F5F5;
  border-radius: 8px;
  border: 1px solid #E6E6E6;
  height: 45px;
  overflow: hidden;
  transition: all 0.5s;
}
.effect-modal-cont-tit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  border-bottom: 1px solid #E6E6E6;
  font-size: 14px;
}
.text-more {
  font-size: 14px;
  color: #999;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.btn-toggle {
  margin-left: 6px;
}
.btn-toggle.top {
  transform: rotate(180deg);
}
.ratio-list {
  padding: 20px 20px 30px;
  display: flex;
}
.ratio-item:first-child {
  width: 330px;
}
.ratio-item:not(:last-child) {
  margin-right: 48px;
}
.ratio-item-tit {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.ratio-label {
  margin-right: 6px;
  font-size: 24px;
  font-weight: bold;
  color: #ccc;
}
.ratio-text {
  font-size: 16px;
  font-weight: bold;
}
.important {
  color: #FE8135;
}
.ratio-subtips {
  margin-left: 6px;
  font-size: 16px;
}
.ratio-tips {
  position: relative;
  display: flex;
  align-items: flex-end;
  font-size: 16px;
}
.ratio-tips:before {
  content: ' ';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  background-color: #FE8135;
}
.icon {
  position: relative;
  margin-bottom: -10px;
  margin-right: 4px;
}

.effect-item {
  width: 204px;
}
.effect-item:not(:last-child) {
  margin-bottom: 32px;
}
.effect-item-top {
  margin-bottom: 6px;
  position: relative;
  width: 204px;
  height: 110px;
  background-color: #000;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.effect-item:not(:last-child) .effect-item-top {
  height: 88px;
}
.effect-item-label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 8px;
  height: 21px;
  line-height: 21px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 0px 0px 17px 0px;
  font-size: 12px;
  color: #fff;
}
.effect-item-image {

}
.icon-thumb-star {
  display: inline-block;
  width: 32px;
  height: 32px;
  vertical-align: middle;
  text-indent: -9999px;
  direction: ltr;
  background: center center no-repeat;
  background-image: url(../../../../assets/images/ad/icon-thumb-star.png);
  background-size: 100%;
}
.effect-item-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}