.wallet {
	background-color: #fff;
  border-radius: 8px;
  line-height: 1.2;
	height: 236px;
	&.higher {
		height: 428px;
	}
}
.title {
  padding: 24px 24px 16px;
  border-bottom: 1px solid #EDEDED;
  font-size: 20px;
  font-weight: bold;
}
.wallet-icon-toggle {
	margin-left: 8px;
	font-size: 16px;
	color: #ccc;
	cursor: pointer;
}
.wallet-cont {
	height: 170px;
	display: flex;
	align-items: center;
}
.wallet-item {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.wallet-tit {
	margin-bottom: 8px;
	font-size: 16px;
	font-weight: bold;
}
.wallet-tit-unit {
	font-size: 14px;
	color: #999;
}
.wallet-price {
	font-size: 28px;
	font-weight: bold;
}