.main {
  min-height: 100%;
}

.sign-content {
  position: relative;
  background: #FAFAFA url('https://cdn.remudooh.com/static/jtt-media-buyer-web/home/banner2.png?v=2') no-repeat 20% center;
  min-height: 100vh;
  background-size: 543px 568px;
}
.sign-content.is-home {
	background: none;
	overflow: hidden;
}