.ad-page {
	position: relative;
}
.ad-page.is-paywait {
	padding-bottom: 88px;
}

.ad-page-cont-wrap {
	display: flex;
	.ad-page-cont:not(:last-child) {
		margin-right: 16px;
	}
}
.ad-page-cont {
	flex: 1;
	position: relative;
	margin-bottom: 16px;
	padding: 24px;
	background-color: #fff;
	border: 1px solid #E6E6E6;
	border-radius: 8px;
}
.ad-page-tit {
	margin: 0 -24px 24px;
	padding-left: 24px;
	padding-bottom: 16px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #EDEDED;
}
.btn-back {
	display: flex;
	align-items: center;
	font-size: 16px;
	color: #FF0032;
	cursor: pointer;
}
.icon-right {
	margin-right: 8px;
	transform: rotate(90deg);
	font-size: 13px;
	color: #FF0032;
}
.btn-back-text {
	
}
.ad-page-tit-text {
	margin-left: 16px;
	padding-left: 16px;
	border-left: 1px solid #ccc;
	font-weight: bold;
	font-size: 20px;
}

.ad-page-cont-inner {
	margin-bottom: 24px;
	position: relative;
	display: flex;
}

.ad-page-top {
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.ad-labels {
	display: flex;
	align-items: center;
}
.ad-label {
	padding: 4px 12px;
	background-color: #C2C2C2;
	border-radius: 4px 0px 0px 4px;
	height: 24px;
}
.ad-label:nth-child(2) {
	background-color: #DBDBDB;
	border-radius: 0 4px 4px 0;
}
.channel-label {
	position: relative;
	margin-left: 8px;
	padding-left: 8px;
}
.channel-label:before {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	content: ' ';
	width: 1px;
	height: 16px;
	background-color: #999;
}

.flex {
	display: flex;
	align-items: center;
}
.ad-tit-label {
	font-size: 16px;
	font-weight: bold;
}
.delivery-name {
	margin-left: 24px;
	font-size: 16px;
}

.ad-form-wrap {
	margin-top: 24px;
	position: relative;
	display: flex;
}
.ad-form-cont {
	flex: 1;
	padding: 16px 24px;
	background-color: #FAFAFA;
	border-radius: 4px;
	border: 1px solid #E6E6E6;
	&:first-child {
		flex: 1.5;
	}
	&:not(:last-child) {
		margin-right: 16px;
	}
	.ad-form-cont-tit {
		margin: 0 -24px;
		padding: 0 24px 16px;
		border-bottom: 1px solid #EDEDED;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
.ad-form {
	margin-top: 16px;
	font-size: 14px;
}
.ad-form-item {
	display: flex;
	align-items: center;
}
.ad-form-item:not(:last-child) {
	margin-right: 60px;
}
.ad-form-label {
	display: flex;
	align-items: center;
	color: #999;
}
.ad-form-icon {
	margin-right: 10px;
}
.text-days {
	font-size: 12px;
	color: #999;
}
.ad-form-value {
	display: flex;
	align-items: center;
}

.ad-progress-wrap {
	margin-top: 16px;
	display: flex;
	align-items: center;
	font-size: 12px;
}
.ad-progress {
	margin-right: 8px;
	width: 640px;
}

.ad-cont-tit {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.btn-all {
	font-size: 14px;
	color: #666;
	cursor: pointer;
}
.icon-more {
	margin-left: 6px;
	display: inline-block;
	font-size: 12px;
	color: #999;
	transform: rotate(-90deg);
}

.report-data {
	margin-top: 16px;
	padding: 42px 24px 32px;
	height: 132px;
	background-color: #F5F5F5;
	border-radius: 8px;
	display: flex;
	position: relative;
	&.pointer {
		cursor: pointer;
	}
}
.report-data.no-record {
	align-items: center;
	justify-content: center;
	font-size: 16px;
	color: #666;
}
.report-item {
	display: flex;
	align-items: center;
}
.report-item:not(:last-child) {
	margin-right: 160px;
}
.report-date {
	position: absolute;
	left: 0;
	top: 0;
	padding: 0 8px;
	height: 28px;
	background-color: #ccc;
	border-radius: 8px 0 8px 0;
	color: #333;
	display: flex;
	align-items: center;
}
.report-item-value {
	display: flex;
	align-items: baseline;
	font-weight: bold;
	font-size: 20px;
	.text-price {
		display: flex;
		align-items: baseline;
		.text-price-unit {
			font-size: 12px;
		}
	}
	.text-maybe {
		margin-left: 8px;
		padding: 0 8px;
		height: 20px;
		line-height: 20px;
		background-color: #FFF8E8;
		border-radius: 12px 12px 12px 0px;
		border: 1px solid #EEC98F;
		font-size: 12px;
		color: #666;
	}
}
.report-item-tips {
	margin-left: 8px;
	padding: 0 8px;
	height: 22px;
	line-height: 22px;
	background-color: #F5F5F5;
	border-radius: 12px 12px 12px 0px;
	font-size: 12px;
	&.is-no-settle {
		background: rgba(226, 164, 90, 0.1);
		color: #FF0032;
	}
}
.report-item-tips.is-error {
	background-color: rgba(251, 62, 99, 0.05);
	color: #FB3E63;
}
.report-item-tips .count {
	margin-left: 8px;
}
.report-item-tips.is-error .count {
	font-weight: bold;
}

.receipt-list {
	margin: 16px 20% 0;
	display: flex;
	align-items: center;
	justify-content: center;
	.receipt-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		line-height: 1.5;
		.receipt-item-value {
			margin-bottom: 8px;
			font-size: 20px;
			font-weight: bold;
		}
		.error {
			color: #F52F3E;
		}
	}
}

.report-label {
	position: absolute;
	right: 0;
	top: 0;
	padding: 0 8px;
	height: 20px;
	line-height: 20px;
	background-color: #FE8135;
	border-radius: 0px 8px 0px 8px;
	font-size: 12px;
	color: #fff;
}

.echarts-panel {
	width: 100%;
	height: 430px;
}

