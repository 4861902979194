.modal-top {
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.modal-top-l {
	display: flex;
	align-items: center;
}
.btn-opt {
	margin-right: 16px;
	display: flex;
	align-items: center;
	padding: 0 18px;
	height: 32px;
	line-height: 32px;
	border-radius: 4px;
	border: 1px solid #E6E6E6;
	cursor: pointer;
}
.btn-icon {
	margin-right: 6px;
	font-size: 12px;
	color: #FF0032;
}

.modal-top-r {
	display: flex;
	align-items: center;
}
.modal-switch-label {
	margin-right: 16px;
	color: #999;
}

.content-item-wrap {
	display: flex;
	align-items: center;
}
.table-head {
	margin-top: 16px;
	margin-bottom: 8px;
	padding: 0 8px;
	height: 36px;
	background-color: #F5F5F5;
	border-radius: 4px;
	display: flex;
	align-items: center;
}
.table-head .table-td {
	padding-left: 16px;
	color: #666;
	/*flex: 1;*/
	display: flex;
	align-items: center;
}
.table-head .table-td:not(:last-child) {
	border-right: 1px solid #DBDBDB;
}
.table-td:nth-child(1),
.content-td:nth-child(1) {
	width: 510px;
}
.table-td:nth-child(2),
.content-td:nth-child(2) {
	width: 240px;
}
.table-td:nth-child(3),
.content-td:nth-child(3) {
	width: 300px;
}
.content-item {
	/*width: calc(100% - 100px);*/
}
.content-td:nth-child(2) {
	flex-direction: column;
}
.content-reasons-tit {
	color: #333 !important;
	display: block !important;
}

.content-list {
	margin-bottom: 24px;
}
.content-item {
	margin: 16px 0;
	display: flex;
}
.content-td {
	line-height: 1.2;
	padding-left: 16px;
	&:nth-child(1) {
		width: 490px;
		padding-left: 0;
	}
}
.content-cont {
	margin-left: 4px;
	display: flex;
}
.content-cont-l {
  position: relative;
  margin-right: 24px;
  width: 200px;
  height: 84px;
  background-color: #000;
  border-radius: 4px;
  overflow: hidden;
}
.content-image {
  width: 100%;
  height: 100%;
	object-fit: contain;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
}
.content-pending-tips {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  font-size: 12px;
  color: #F5F5F5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-del-tips {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 4px;
  line-height: 1.5;
  font-size: 12px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content-image-mask {
	position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.content-duration-wrap {
	position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.btn-play {
	width: 20px;
	height: 20px;
	border: 2px solid #fff;
	border-radius: 50%;
	color: #fff;
	font-size: 14px;
	padding-left: 2px;
}
.content-duration {
	margin-top: 4px;
  font-size: 12px;
  color: #fff;
}
.content-cont-r {
	font-size: 12px;
	color: #999;
}
.content-cont-tit {
	margin-bottom: 8px;
	font-size: 14px;
	font-weight: bold;
	color: #333;
}
.content-number {
	margin-bottom: 4px;	
}
.content-reasons {
	color: #FF4D4F;
}

.modal-foot {
	margin-top: 24px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	border-top: 1px solid #E6E6E6;
	padding: 12px 0 0;
	.btn-confirm {
		padding: 0 24px;
		height: 40px;
		line-height: 40px;
		background-color: #FF0032;
		border-radius: 4px;
		font-size: 16px;
		color: #fff;
		cursor: pointer;
		&.is-disabled {
			opacity: 0.3;
			cursor: default;
		}
	}
}

.industry-label {
	padding: 0 8px;
	height: 20px;
	line-height: 18px;
	border-radius: 12px;
	border: 1px solid #CCCCCC;
	font-size: 12px;
	color: #666;
	&:not(:first-child) {
		margin-left: 8px;
	}
}