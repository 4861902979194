.sidebar {
  min-height: 100%;
  width: 224px;
  background-color: #fff;
  border-right: 1px solid #E6E6E6;
  overflow: auto;
  z-index: 998;
}

.iconfont {
	margin-right: 4px;
}