.report-base-info {
  background: #F5F5F5;
  border-radius: 8px;
  line-height: 1;
  .report-day-tit {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .report-day-tit-side {
    display: flex;
    align-items: center;
    &:first-child {
      border-radius: 8px 0px 8px 0px;
    }
  }
  .report-date {
    padding: 0 8px;
    height: 24px;
    line-height: 24px;
    background-color: #ccc;
    border-radius: 8px 0px 0px 0px;
    font-size: 12px;
  }
  .date-label {
    margin-left: 4px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    background-color: #FF0032;
    border-radius: 50%;
    font-weight: bold;
    color: #fff;
    font-size: 12px;
    text-align: center;
  }
  .report-settle-status {
    padding: 0 8px;
    height: 24px;
    line-height: 24px;
    background-color: #45C9B8;
    border-radius: 0px 0px 8px 0px;
    font-size: 12px;
    color: #fff;
  }
  .report-label {
    margin-right: 10rpx;
    padding: 0 8rpx;
    height: 32rpx;
    line-height: 32rpx;
    background-color: rgba(27, 29, 37, 0.7);
    border-radius: 8rpx;
    font-size: 21rpx;
    color: #fff;
  }
  .report-status {
    padding: 8px 16px 0;
    font-weight: bold;
    color: #FE8135;
  }
}

.report-data {
  margin-right: 200px;
  padding: 36px 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .report-item {
    display: flex;
    align-items: center;
  }
  .report-item-label {
    margin-right: 16px;
  }
  .report-item-value {
    display: flex;
    align-items: center;
  }
  .report-number {
    font-size: 20px;
    font-weight: bold;
  }
  .text-price-unit {
    font-size: 12px;
  }
}
.report-tips-label {
  margin-left: 8px;
  padding: 0 8px;
  height: 20px;
  line-height: 18px;
  background-color: #FFF8E8;
  border-radius: 12px 12px 12px 0px;
  border: 1px solid #EEC98F;
  font-size: 12px;
  color: #666;
  &.is-important {
    background-color: rgba(251, 62, 99, 0.05);
    border-color: rgba(251, 62, 99, 0.05);
    color: #FB3E63;
  }
}

.report-list {
  margin: 24px 0;
  .entity-name {
    font-weight: bold;
  }
  .rate-list {
    display: flex;
    align-items: center;
  }
  .rate-item {
    font-size: 16px;
    color: #FE8135;
  }
  .table-cell {
    display: flex;
    align-items: center;
  }
  .text-price-unit {
    font-weight: bold;
  }
  .error {
    color: #FF4D4F;
  }
  .success {
    color: #45C9B8;
  }
  .report-img-wrap {
    width: 112px;
    height: 63px;
    overflow: hidden;
    background-color: #F5F5F5;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #999;
  }
  .report-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
}