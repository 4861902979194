.ad-page {
	
}
.ad-page-tit {
  margin: 16px 0;
  font-size: 12px;
  font-weight: bold;
}

.ad-cont {
	padding: 24px;
  margin: 16px 0;
  background-color: #fff;
  border-radius: 8px;
}
.ad-cont-tit {
	padding-bottom: 16px;
	font-size: 20px;
	font-weight: bold;
}

.ad-list-top {
	margin-bottom: 24px;
	display: flex;
	justify-content: space-between;
}

.tab-list {
	flex: 1;
	margin-left: -24px;
	padding: 0 24px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #EDEDED;
}
.tab-item {
	position: relative;
	margin-right: 10px;
	padding: 0 36px;
	height: 48px;
	font-weight: bold;
	font-size: 14px;
	text-align: center;
	line-height: 48px;
	cursor: pointer;
}
.tab-item.is-selected {
	font-size: 16px;
}
.tab-item.is-selected:before {
	content: ' ';
	position: absolute;
	left: 50%;
	bottom: 0;
	width: 24px;
	height: 3px;
	background-color: #FF0032;
	transform: translateX(-50%);
}

.ad-list {
	margin-bottom: 40px;
}
.ad-item {
	position: relative;
	padding: 40px 24px 24px;
	margin-bottom: 16px;
	background-color: #FAFAFA;
	border-radius: 8px;
	border: 1px solid #E6E6E6;	
}
.ad-labels {
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
}
.ad-label {
	padding: 4px 12px;
	background-color: #C2C2C2;
	border-radius: 8px 0px 0px 0px;
	height: 24px;
}
.ad-label:nth-child(2) {
	background-color: #DBDBDB;
	border-radius: 0 0px 8px 0;
}
.channel-label {
	position: relative;
	margin-left: 8px;
	padding-left: 8px;
}
.channel-label:before {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	content: ' ';
	width: 1px;
	height: 16px;
	background-color: #999;
}

.ad-item-top-r {
	position: absolute;
	right: 24px;
	top: 12px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.ad-replay-pay {
	margin-right: 8px;
	padding: 0 8px;
	height: 24px;
	line-height: 22px;
	background-color: #FFF5E8;
	border-radius: 12px;
	border: 1px solid #FEB77C;
	color: #FE8135;
	font-size: 12px;
}
.ad-status {
	color: #ff300f;
	font-size: 14px;
}

.ad-item-cont {
	display: flex;
	cursor: pointer;
}

.ad-material {
	margin-right: 16px;
	position: relative;
	width: 180px;
	height: 75px;
	background-color: #fff;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.ad-material-default {
	width: 180px;
	height: 75px;
	background-image: url(../../../assets/images/ad/bg-default-material.png);
}
.ad-material-image {
	width: 100%;
	height: 75px;
	object-fit: contain;
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.3);
}
.ad-material-tips {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7);
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 12px;
}

.ad-item-r {
	flex: 1;
}
.delivery-name {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.delivery-name-text {
		font-weight: bold;
		font-size: 16px;
	}
}
.ad-price {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.ad-price-item {
	display: flex;
	align-items: baseline;
}
.ad-price-label {
	margin-right: 4px;
}
.ad-price-value {
	display: flex;
	align-items: baseline;
}
.text-price {
	font-weight: bold;
	display: flex;
	align-items: baseline;
	.text-price-unit {
		font-size: 14px;
	}
	.text-price-int {
		font-size: 16px;
	}
	.text-price-dec {
		font-size: 14px;
	}
}

.ad-form {
	flex: 1;
	display: flex;
	flex-wrap: wrap;
	font-size: 14px;
}
.ad-form-item {
	margin-bottom: 12px;
	display: flex;
	align-items: center;
	&:not(:last-child) {
		margin-right: 66px;
	}
}
.ad-form-label {
	display: flex;
	align-items: center;
	color: #999;
}
.ad-form-icon {
	margin-right: 10px;
}
.text-days {
	font-size: 12px;
	color: #999;
}
.ad-form-value {
	display: flex;
	align-items: center;
}
.icon-crown {
	margin: 0 2px;
	color: #fcae50;
}
.ad-form-value-tips {
	margin-left: 10px;
	padding: 0 16px;
	height: 20px;
	line-height: 20px;
	background-color: #eee;
	border-radius: 10px;
	font-size: 12px;
	color: #FB3E63;
}

.ad-item-opts {
	position: absolute;
	right: 24px;
	bottom: 20px;
	display: flex;
	align-items: center;
	font-size: 12px;
}

.ad-content-tips {
}
.ad-content-status {
	font-weight: bold;
}
.ad-end-date {
	margin: 0 4px;
	color: #FF0032;
}

.ad-delivery-days {
	margin: 0 4px;
	font-weight: bold;
	color: #FF0032;
}

.ad-close-reason .ad-close-icon {
	margin-right: 6px;
	font-size: 13px;
	color: #FF4D4F;
}

.btn-confirm {
	margin-left: 16px;
	padding: 0 8px;
	line-height: 24px;
	height: 24px;
	background-color: #fff;
	border-radius: 4px;
	border: 1px solid #E6E6E6;
	font-size: 14px;
	cursor: pointer;
	&[disabled] {
		background-color: #ddd;
		color: #999;
		cursor: default;
	}
}