.modal-wrap .ant-modal-content {
  border-radius: 32px;
}
.modal {
  border-radius: 32px;
}
.content {
  /*width: 624px;*/
  line-height: 1;
}
.header {
  padding: 40px 0 80px;
  text-align: center;
}
.title {
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: bold;
  line-height: 1.5;
}
.subtitle {
  font-size: 20px;
  color: #9E9E9E;
}

.form {
  width: 510px;
  margin: 0 auto;
  padding-bottom: 1px;
}
.ipt {
  height: 56px;
  border: 1px solid #EDEDED;
  border-radius: 4px;
  font-size: 16px;
}
.ipt::placeholder {
  font-size: 16px;
}

.select {
  padding: 8px 0;
  border: 1px solid #EDEDED;
  border-radius: 4px;
}
.select::placeholder {
  padding-left: 12px;
  font-size: 16px;
  color: #999;
}
.select.short {
  margin-right: 24px;
  width: 240px;
}
.select.short:last-child {
  margin-right: 0;
}
.select .ant-select-selector {
  height: 56px;
  border: 1px solid #EDEDED;
  border-radius: 4px;
}

.footer {
}

.confirm-btn {
  margin-top: 48px;
  margin-bottom: 24px;
  width: 100%;
  border: none;
  height: 56px;
  border-radius: 4px;
  background-color: #ff0032;
  color: #fff;
  font-size: 20px;
  border: none;
}

.confirm-btn:hover {
  background-color: #ff0032;
  color: #fff;
}

.confirm-btn[disabled],
.confirm-btn[disabled]:hover, .confirm-btn[disabled]:focus, .confirm-btn[disabled]:active {
  background-color: #ff0032;
  opacity: 0.5;
  color: #fff;
}