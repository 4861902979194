
.ad-form {
	/* width: 1000px; */
}
.ad-form-item {
	margin: 40px 0;
	display: flex;
	align-items: center;
}
.ad-form-label {
	margin-right: 20px;
	width: 160px;
	display: flex;
	align-items: center;
}
.ad-form-icon {
	margin-right: 8px;
}
.flex {
	display: flex;
	align-items: center;
}
.baseline {
	display: flex;
	align-items: baseline;
}
.ad-form-select {
	padding: 0 10px;
	width: 365px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 40px;
  line-height: 40px;
	border: 1px solid #E6E6E6;
	border-radius: 2px;
	cursor: pointer;
	&.start {
		justify-content: flex-start;
	}
}
.ad-form-placeholder {
	font-size: 16px;
	color: rgba(0, 0, 0, 0.25);
}
.icon-arrow {
	color: rgba(0, 0, 0, 0.25);
}
.slider-wrap {
	display: flex;
}
.slider-cont {
	margin: -10px 10px 0;
}
.ad-form-value-tips-r {
	margin-left: 40px;
	font-size: 12px;
	color: #999;
}
.ad-form-value-tips {
	margin-top: 16px;
	font-size: 12px;
	color: #999;
}

.duration-item {
	margin-right: 16px;
	padding: 0 40px;
	height: 40px;
	line-height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #FFFFFF;
	border-radius: 20px;
	border: 1px solid #E6E6E6;
	text-align: center;
	cursor: pointer;
	&.is-selected {
		border-color: #FF0032;
	}
	.duration-item-tips {
		margin-left: 16px;
		font-size: 12px;
		color: #999;
	}
}

.flex-end {
	display: flex;
	justify-content: space-around;
}

.btn-create {
	width: 160px;
	height: 48px;
	background-color: #FF0032;
	border-radius: 4px;
	font-weight: bold;
	font-size: 16px;
	color: #fff;
	border: none;
	cursor: pointer;
}
.btn-create[disabled] {
	cursor: default;
	opacity: 0.6;
}


.periods {
	margin-top: 24px;
	width: 784px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 88px;
	background: #F5F5F5;
	border-radius: 8px;
	border: 1px solid #E6E6E6;
}
.periods-label {
	padding: 0 16px;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #E6E6E6;
	line-height: 1.2;
	&:first-child {
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		position: relative;
		&::after,
		&::before {
			content: ' ';
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			border: 10px solid transparent;
		}
		&::after {
			border-right-color: #F5F5F5;
		}
	}
	&:last-child {
		position: relative;
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
		&::after,
		&::before {
			content: ' ';
			position: absolute;
			left: -20px;
			top: 50%;
			transform: translateY(-50%);
			border: 10px solid transparent;
		}
		&::after {
			border-right-color: #E6E6E6;
		}
	}
}
.periods-list-wrap {
	padding: 0 16px 0 6px;
}
.periods-list-label {
	margin-bottom: 10px;
	text-align: center;
}
.periods-list {
	display: flex;
	color: #999;
	.periods-item {
		width: 63px;
		text-align: center;
		&:not(:last-child) {
			margin-right: 1px;
		}
		.periods-item-label {
			margin-bottom: 8px;
			width: 100%;
			height: 8px;
			background-color: #EDEDED;
			&.is-selected {
				background-color: #FF0032;
			}
		}
	}
}