.ad-page-cont {
	position: relative;
	margin-bottom: 16px;
	padding: 24px;
	background-color: #fff;
	border: 1px solid #E6E6E6;
	border-radius: 8px;
}
.ad-tit-label {
	font-size: 16px;
	font-weight: bold;
}

.ad-page-bottom {
	padding: 16px 24px;
	background-color: #fff;
	border-radius: 8px;
	height: 72px;
	border: 1px solid #E6E6E6;
	display: flex;
	align-items: center;
	justify-content: space-between;
	&.no-flex {
		display: block;
		height: auto;
	}
	.ad-page-form-item {
		display: flex;
		align-items: center;
		&:not(:last-child) {
			margin-bottom: 16px;
		}
		&:not(.important) {
			.text-price {
				color: #333;
			}
		}
		&.special {
			.ad-page-form-value {
				.text-price {
					color: #FE8135;
				}
			}
		}
	}
	.ad-page-form-label {
		margin-right: 10px;
		min-width: 200px;
		font-weight: bold;
	}
}
.ad-page-bottom.bottom {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	box-shadow: 0px -2px 8px 1px rgba(0,0,0,0.15);
	border-radius: 8px 8px 0px 0px;
}
.ad-page-bottom.confirm {
	box-shadow: 0px -2px 8px 1px rgba(0,0,0,0.15);
	border-radius: 8px 8px 0px 0px;
}
.ad-page-bottom-l {
	display: flex;
	align-items: center;
}
.important {
	font-weight: bold;
	color: #333;
}
.ad-page-bottom-label {
	margin-right: 8px;
	font-size: 16px;
	font-weight: bold;
}
.bottom .ad-page-bottom-label {
	font-weight: normal;
}
.icon-tips {
	margin-right: 8px;
	font-size: 14px;
	color: #ccc;
	cursor: pointer;
}
.text-price {
	font-size: 16px;
	color: #FF0032;
}
.text-price-int {
	font-size: 24px;
}
.ad-page-bottom-r {
	display: flex;
	align-items: center;
}

.btn-cancel {
	padding: 0 24px;
	font-size: 16px;
	height: 40px;
	line-height: 38px;
	border-radius: 4px;
	border: 1px solid #FF0032;
	color: #FF0032;
	cursor: pointer;
}
.btn-comfirm {
	margin-left: 16px;
	padding: 0 24px;
	line-height: 24px;
	height: 40px;
	border-radius: 4px;
	background-color: #FF0032;
	border: 1px solid #FF0032;
	font-size: 16px;
	color: #fff;
	cursor: pointer;
}
.btn-comfirm[disabled] {
	background-color: #F5F5F5;
	border-color: #E6E6E6;
	color: #ccc;
	cursor: default;
}
.big-margin {
	margin-left: 48px;
}

.ad-material-tips {
	font-size: 16px;
	color: #999;
}
.ad-material-link {
	color: #FF0032;
	cursor: pointer;
}

.text-price-wrap {
	margin-right: 48px;
	display: flex;
	align-items: baseline;
}
.text-price-reduce {
	margin-left: 16px;
	display: flex;
	align-items: baseline;
	.text-price {
		color: #333;
		font-size: 16px;
		.text-price-int,
		.text-price-dec {
			font-size: 16px;
			font-weight: bold;
		}
	}
}