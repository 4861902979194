.level-list {
  display: flex;
}
.level-item {
  flex: 1;
  background: linear-gradient( 133deg, #FFFFFF 0%, #F3DAAC 100%);
  border-radius: 24px;
  border: 1px solid #E6E6E6;
  color: #E2A45A;
  &:not(:last-child) {
    margin-right: 16px;
    background: linear-gradient( 133deg, #FFFFFF 0%, #FFCEA0 100%);
    color: #FE8135;
    .level-icon {
      background-image: url(../../../../../../assets/images/membership/icon-R1.png);
    }
  }

  .level-top {
    padding: 24px;
    display: flex;
    height: 190px;
  }
  .level-icon {
    margin-right: 24px;
    width: 98px;
    height: 94px;
    background-image: url(../../../../../../assets/images/membership/icon-R2.png);
  }
  .level-top-r {
    position: relative;
    flex: 1;
  }
  .level-top-tit {
    position: relative;
    margin-top: -15px;
    margin-bottom: -25px;
    display: flex;
    align-items: baseline;
  }
  .level-top-label {
    margin-right: 16px;
    font-size: 64px;
    font-style: italic;
    color: #D25F21;
    font-weight: bold;
  }
  .level-valid-period {
    position: absolute;
    right: 0;
    top: 0;
  }
  .level-top-progress-wrap {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  .level-top-progress {
    margin-right: 16px;
    width: 240px;
  }
  .score-number {
    font-weight: bold;
  }
  .btn-right {
    display: inline-block;
    transform: rotate(-90deg);
    font-size: 11px;
    cursor: pointer;
  }

  .right-list {
    padding: 24px 20px;
    border-top: 1px solid rgba(254,129,53,0.15);;
    color: #333;
    .right-list-tit {
      margin-bottom: 24px;
      font-size: 18px;
      font-weight: bold;
    }
    .right-item {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      font-size: 16px;
    }
    .right-item-index {
      margin-right: 16px;
      font-style: italic;
      color: #D25F21;
    }
    .right-item-label {
      padding: 0 16px;
      height: 37px;
      line-height: 37px;
      background-color: #FEB77C;
      border-radius: 8px 0px 0px 8px;
      color: #fff;
      &:not(:last-child) {
        margin-left: 16px;
      }
      &:last-child {
        background-color: #FE8135;
        border-radius: 0px 8px 8px 0px;
      }
    }
  }
}

.rules-cont {
  margin: 24px 0;
  background-color: #F5F5F5;
  border-radius: 8px;
  border: 1px solid #E6E6E6;
  height: 45px;
  overflow: hidden;
  transition: all 0.5s;
}
.rules-cont-tit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  height: 64px;
  border-bottom: 1px solid #E6E6E6;
  font-size: 16px;
  font-weight: bold;
}
.text-more {
  font-size: 14px;
  color: #999;
  display: flex;
  align-items: center;
  font-weight: normal;
  cursor: pointer;
}
.btn-toggle {
  margin-left: 6px;
}
.btn-toggle.top {
  transform: rotate(180deg);
}
.rules-item {
  margin-bottom: 50px;
  padding-left: 20px;
  line-height: 1.2;
}
.rules-item-tit {
  position: relative;
  margin-bottom: 16px;
  padding-left: 20px;
  font-size: 18px;
  font-weight: bold;
  &::before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 4px;
    width: 4px;
    height: 16px;
    background-color: #FF0032;
  }
}
.rules-item-text {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #666;
}
.icon-level {
  margin-right: 8px;
  display: inline-block;
  width: 17px;
	height: 16px;
  &.icon-level-1 {
    background-image: url(../../../../../../assets/images/membership/icon-R1.png);
  }
  &.icon-level-2 {
    background-image: url(../../../../../../assets/images/membership/icon-R2.png);
  }
}
.rules-item-subtit {
  margin: 16px 0;
  font-size: 16px;
  font-weight: bold;
}