.welcome-wrap {
	margin: 10px auto;
  width: 1232px;
  height: calc(100% - 120px);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.welcome-cont {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pic-welcome {
	margin-bottom: 20px;
	width: 402px;
	height: 402px;
	background-image: url('../../../assets/images/common/welcome.png');
}

.welcome-tit {
  font-size: 24px;
}
.welcome-tips {
	margin: 20px 0 30px;
  color: #999;
}
.text-important {
	color: #FD8637;
}
.mini-code {
	margin: 0 auto;
	width: 168px;
	height: 208px;
	background-image: url('../../../assets/images/common/mini-code.jpg');
}