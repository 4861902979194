.point-top {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #EDEDED;
  line-height: 1.2;
  .point-name {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
  }
  .point-name-text {
    margin-right: 18px;
    font-size: 20px;
    font-weight: bold;
  }
  .icon-tips {
    margin-left: 8px;
    color: #ccc;
    cursor: pointer;
  }
  .entity-name {
    color: #666;
  }
  .rate-list {
    display: flex;
    align-items: center;
  }
  .rate-item {
    font-size: 16px;
    color: #FE8135;
  }
}

.ad-form {
  line-height: 1.2;
}
.ad-form-item {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}
.ad-form-item:not(:last-child) {
  margin-right: 60px;
}
.ad-form-label {
  display: flex;
  align-items: center;
  color: #999;
}
.ad-form-icon {
  margin-right: 10px;
}
.text-days {
  font-size: 12px;
  color: #999;
  .important {
    color: #F64F4F;
  }
}
.ad-form-value {
  display: flex;
  align-items: center;
}

.calendar-tips {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.calendar-tips-item {
  display: flex;
  align-items: center;
  color: #666;
  &:not(:last-child) {
    margin-right: 40px;
  }
  .icon-delivery {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    background-color: #FFF3F4;
    border: 1px solid #E6E6E6;
  }
  .text-important {
    /* color: #FF0032; */
    cursor: pointer;
  }
  .btn-more {
    margin-left: 8px;
    color: #999;
    font-size: 12px;
    cursor: pointer;
    transform: rotate(90deg);
  }
}

.calendar-cell {
  margin: 0 2px;
  height: 80px;
  border-top: 1px solid #E6E6E6;
  &.is-today {
    border-top-color: #FF0032;
  }
}
.calendar-delivery-bg {
  background-color: #FFE8E9;
  text-align: center;
  font-size: 16px;
  .text-price {
    font-size: 18px;
    color: #FF0032;
  }
  .price-unit {
    font-size: 12px;
  }
}
.calendar-date {
  padding: 7px 14px 0 0;
  text-align: right;
  font-weight: normal;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 24px;
  border-top: 1px solid #E6E6E6;
  .modal-footer-label {
    font-size: 14px;
  }
}