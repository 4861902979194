.ad-page {
	position: relative;
}
.ad-page.is-paywait {
	padding-bottom: 88px;
}

.ad-page-cont {
	margin-bottom: 16px;
	padding: 24px;
	background-color: #fff;
	border: 1px solid #E6E6E6;
	border-radius: 8px;
}
.ad-page-tit {
	margin: 0 -24px 24px;
	padding-left: 24px;
	padding-bottom: 16px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #EDEDED;
}
.btn-back {
	display: flex;
	align-items: center;
	font-size: 16px;
	color: #FF0032;
	cursor: pointer;
}
.icon-right {
	margin-right: 8px;
	transform: rotate(90deg);
	font-size: 13px;
	color: #FF0032;
}
.btn-back-text {
	
}
.ad-page-tit-text {
	margin-left: 16px;
	padding-left: 16px;
	border-left: 1px solid #ccc;
	font-weight: bold;
	font-size: 20px;
}

.entity-box {
	position: relative;
	padding-bottom: 24px;
	display: flex;
	line-height: 1.2;
	border-bottom: 1px solid #EDEDED;
	.btn-more {
		position: absolute;
		right: 0;
		color: #666;
		display: flex;
		align-items: center;
		cursor: pointer;
		.btn-right {
			transform: rotate(-90deg);
		}
	}
}
.entity-item-l {
	position: relative;
	margin-right: 16px;
	width: 88px;
	height: 88px;
	border-radius: 8px;
}
.entity-image {
	width: 88px;
	height: 88px;
	border-radius: 8px;
}
.chain-name {
	position: absolute;
	left: 0;
	bottom: 0;
	padding: 0 6px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 0 4px 0 4px;
  height: 16px;
  line-height: 16px;
  font-size: 12px;
  color: #FEC1A1;
}
.entity-tit {
	margin-bottom: 8px;
	font-size: 16px;
	font-weight: bold;
	display: flex;
	align-items: center;
}
.entity-other-labels {
	display: flex;
	align-items: center;
}
.entity-other-label {
	margin-left: 8px;
	padding: 0 8px;
	height: 20px;
	line-height: 18px;
	background-color: #FFF8E8;
	border-radius: 10px;
	border: 1px solid #EEC98F;
	font-size: 12px;
	color: #FF0032;
	font-weight: normal;
}
.entity-other-label.check {
	background-color: #FFF5E8;
	border-color: #FEB77C;
	color: #FE8135;
}
.entity-tips {
	margin-bottom: 8px;
	font-size: 12px;
	color: #999;
}


.table-head {
	margin-top: 16px;
	margin-bottom: 8px;
	padding: 0 8px;
	height: 36px;
	background-color: #F5F5F5;
	border-radius: 4px;
	display: flex;
	align-items: center;
	color: #666;
}
.table-td {
	padding-left: 16px;
	flex: 0.8;
	display: flex;
	align-items: center;
}
.table-head .table-td:not(:last-child) {
	border-right: 1px solid #DBDBDB;
}
.table-td:nth-child(1) {
	flex: 2;
}
.table-td:nth-child(2) {
	flex: 0.8;
}

.point-item {
	margin: 24px 0;
	display: flex;
	align-items: center;
	line-height: 1.2;
}
.flex-start {
	align-items: flex-start;
}
.point-checkbox {
	margin: 34px 16px 0 -16px;
}
.point-img-wrap {
	margin-right: 16px;
	width: 88px;
	height: 88px;
	border-radius: 8px;
}
.point-img {
	width: 88px;
	height: 88px;
	border-radius: 8px;
}
.point-tit {
	margin-bottom: 8px;
	font-weight: bold;
	font-size: 16px;
}
.point-labels {
	display: flex;
	flex-wrap: wrap;
}
.point-label {
	margin-bottom: 4px;
	margin-right: 8px;
	padding: 0 8px;
	height: 20px;
	line-height: 18px;
	border-radius: 12px;
	border: 1px solid #ccc;
	font-size: 12px;
	color: #666;
}
.special-number {
	color: #FE8135;
}
.table-td.table-link {
	font-size: 14px;
	color: #FF0032;
	cursor: pointer;
}

.table-opts {
	padding: 24px 0 0;
	display: flex;
	align-items: center;
	border-top: 1px solid #EDEDED;
	font-size: 16px;
	color: #999;
}
.table-opts-label {
	padding-left: 10px;
	border-left: 1px solid #DBDBDB;
	color: #999;
}
.table-opts-total {
	font-weight: bold;
	color: #333;
}
.table-opts-selected {
	font-weight: bold;
	color: #FF0032;
}

.ad-page-bottom {
	padding: 16px 24px;
	background-color: #fff;
	border-radius: 8px;
	height: 72px;
	border: 1px solid #E6E6E6;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: 0px -2px 8px 1px rgba(0,0,0,0.15);
	border-radius: 8px 8px 0px 0px;
	.text-price {
		font-size: 16px;
		color: #FE8135;
	}
	.text-price-int {
		font-size: 24px;
	}
}
.ad-page-tips {
	display: flex;
	align-items: center;
	font-size: 16px;
	color: #999;
}
.ad-page-tips-label {
	color: #333;
}
.ad-page-bottom-l {
	display: flex;
	align-items: center;
}
.ad-page-bottom-label {
	margin-right: 8px;
	font-size: 16px;
	font-weight: bold;
}
.bottom .ad-page-bottom-label {
	font-weight: normal;
}
.icon-tips {
	margin-right: 8px;
	font-size: 14px;
	color: #ccc;
	cursor: pointer;
}
.ad-page-bottom-r {
	display: flex;
	align-items: center;
}
.btn-comfirm {
	margin-left: 16px;
	padding: 0 24px;
	line-height: 24px;
	height: 40px;
	border-radius: 4px;
	background-color: #FF0032;
	border: 1px solid #FF0032;
	font-size: 16px;
	color: #fff;
	cursor: pointer;
}
.btn-comfirm[disabled] {
	background-color: #F5F5F5;
	border-color: #E6E6E6;
	color: #ccc;
	cursor: default;
}
.big-margin {
	margin-left: 48px;
}

