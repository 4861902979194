.code {
  top: 0;
  right: 16px;
  line-height: 56px;
  color: #ff0032;
  cursor: pointer;
  z-index: 1;
}

.sms-tips {
  position: absolute;
  margin-top: -16px;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.icon-sms-tips {
  margin-right: 8px;
  color: #45C9B8;
}
.error-message {
  color: #FF3838;
}

.submit-btn, .submit-btn:hover, .submit-btn:focus {
  border: 1px solid #ff0032;
  background-color: #ff0032;
  color: #fff;
}