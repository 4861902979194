.order {
  margin-bottom: 16px;
  background-color: #FFFFFF;
  border-radius: 8px;
  height: 236px;
}

.title {
  padding: 24px 24px 16px;
  border-bottom: 1px solid #EDEDED;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.all {
  font-weight: normal;
  font-size: 14px;
  color: #999;
  cursor: pointer;
}

.order-status-list {
  padding: 50px 146px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #999;
}
.order-status-item {
  position: relative;
  text-align: center;
  cursor: pointer;
}
.order-status-icon {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.count {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 0 8px;
  height: 20px;
  background-color: #FF3838;
  border: 2px solid #FFFFFF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #fff;
  font-size: 12px;
}
.count.hide {
  display: none;
}