.intro-page {
  line-height: 1;
}
.intro-cont {
  margin-bottom: 24px;
  padding: 16px;
  background-color: #F5F5F5;
  border: 1px solid #EDEDED;
  border-radius: 8px;

}
.intro-tit {
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 16px;
}
.intro-tips {
  color: #666;
  line-height: 1.5;
}
.intro-p {
  margin-bottom: 8px;
}

.intro-table {
  margin-top: 16px;
  .intro-th {
    display: flex;
    background-color: #F5F5F5;
    .intro-td {
      border-left: 1px solid #EDEDED;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  .intro-tr {
    position: relative;
    display: flex;
    align-items: center;
    color: #666;
  }
  .intro-td {
    flex: 0.5;
    padding: 16px 0 16px 32px;
    line-height: 1.2;
    white-space: nowrap;
    &:last-child {
      flex: 1.2;
    }
  }

  .intro-tr:nth-child(2n - 1) .intro-td:not(:first-child) {
    background-color: #FFFCF7;
  }
  .intro-tr .intro-td:not(:first-child) {
    border-bottom: 1px solid #F5F5F5;
  }
  .intro-tr:nth-child(4n) .intro-td:first-child {
    border-bottom: 1px solid #F5F5F5;
  }
  .intro-tr .intro-td:nth-child(2n) {
    border-left: 1px solid #F5F5F5;
  }

  .rate-list {
    display: flex;
    align-items: center;
  }
  .rate-item {
    font-size: 16px;
    color: #FE8135;
  }
}
