/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'ifont';  /* Project id 2511901 */
  src: url('//at.alicdn.com/t/c/font_2511901_3v17panrman.woff2?t=1726218539143') format('woff2'),
       url('//at.alicdn.com/t/c/font_2511901_3v17panrman.woff?t=1726218539143') format('woff'),
       url('//at.alicdn.com/t/c/font_2511901_3v17panrman.ttf?t=1726218539143') format('truetype');
}

.ifont {
  font-family: "ifont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-reload:before {
  content: "\e65f";
}

.i-close:before {
  content: "\e6a8";
}

.i-checked:before {
  content: "\e882";
}

.i-more:before {
  content: "\e6a7";
}

.i-pencile-simple:before {
  content: "\e6e5";
}

.i-play:before {
  content: "\e680";
}

.i-upload:before {
  content: "\e61d";
}

.i-image:before {
  content: "\e6a5";
}

.i-fire:before {
  content: "\e669";
}

.i-crown:before {
  content: "\e6a4";
}

.i-minicode-0:before {
  content: "\e63e";
}

.i-email:before {
  content: "\e6a2";
}

.i-tel:before {
  content: "\e6a3";
}

.i-activity:before {
  content: "\e6a1";
}

.i-store:before {
  content: "\e682";
}

.i-goods:before {
  content: "\e683";
}

.i-crown-home:before {
  content: "\e684";
}

.i-search:before {
  content: "\e604";
}

.i-auth:before {
  content: "\e6e3";
}

.i-trash:before {
  content: "\e6e4";
}

.i-edit:before {
  content: "\e6e2";
}

.i-video:before {
  content: "\e6e1";
}

.i-folder:before {
  content: "\e6e0";
}

.i-dollar-o:before {
  content: "\e6b6";
}

.i-user-bg:before {
  content: "\e6b4";
}

.i-tips:before {
  content: "\e6b2";
}

.i-question:before {
  content: "\e6b1";
}

.i-robot:before {
  content: "\e6b0";
}

.i-video-play:before {
  content: "\e6a6";
}

.i-checked-trangle:before {
  content: "\e6a0";
}

.i-trangle-t:before {
  content: "\e69e";
}

.i-trangle-b:before {
  content: "\e69f";
}

.i-folder-download:before {
  content: "\e69d";
}

.i-message:before {
  content: "\e69c";
}

.i-auth-personal:before {
  content: "\e69a";
}

.i-auth-company:before {
  content: "\e69b";
}

.i-bubble-o:before {
  content: "\e699";
}

.i-bubble:before {
  content: "\e698";
}

.i-dollar:before {
  content: "\e697";
}

.i-user:before {
  content: "\e696";
}

.i-cinema:before {
  content: "\e695";
}

.i-cell:before {
  content: "\e694";
}

.i-home:before {
  content: "\e693";
}

.i-live:before {
  content: "\e691";
}

.i-ad:before {
  content: "\e692";
}

.i-building:before {
  content: "\e690";
}

.i-close-o:before {
  content: "\e68f";
}

.i-shield:before {
  content: "\e68e";
}

.i-phone:before {
  content: "\e68d";
}

.i-mail:before {
  content: "\e68c";
}

.i-wxmini:before {
  content: "\e68b";
}

.i-lock:before {
  content: "\e68a";
}

.i-user-o:before {
  content: "\e689";
}

