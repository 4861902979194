.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 72px;
  background-color: #fff;
  border-bottom: 1px solid #E6E6E6;
  color: #333;
  z-index: 999;
  font-size: 16px;
}
.header.static {
  position: static;
}
.header.is-home {
  height: 64px;
  background-color: #fff;
  color: #333;
}
.header.is-first-page.is-home {
  background-color: transparent;
  color: #fff;
}
.header.is-first-page.is-home .sign-in {
  color: #fff;
}
.header.is-first-page.is-home .sign-in:hover {
  color: #FF0032;
}
.header-inner {
  width: 100%;
  margin-left: 24px;
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header.is-home {
  border-bottom: none;
}
.header.is-home .header-inner {
  width: 100%;
}
.left {
  /*cursor: pointer;*/
}
.logo {
  width: 69px;
  height: 32px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url('../../assets/logo.png');
  cursor: pointer;
}
.header.is-first-page.is-home .logo {
  background-image: url('../../assets/logo-white.png')
}

.header-r {
  display: flex;
  align-items: center;
}
.header-r-item {
  padding-left: 48px;
  display: flex;
  align-items: center;
}
.header-r-item.last {
  position: relative;
  margin-left: 48px;
}
.header-r-item.last:before {
  content: ' ';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 24px;
  background-color: #E5E5E5;
}
.btn-platform {
  margin-left: 48px;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
}

.to-media {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon-mini {
  margin-right: 10px;
  font-size: 22px;
  color: #ccc;
  /*background-color: #ccc;
  border-radius: 50%;*/
}
.miniprogram {
  position: relative;
  cursor: pointer;
}
.miniprogram:hover {
  color: #FF0032;
}
.miniprogram:hover .mini-code {
  display: block;
}
.mini-code {
  display: none;
  position: absolute;
  top: 30px;
  left: 50%;
  padding: 10px 30px 20px;
  border-radius: 0 0 8px 8px;
  background-color: #fff;
  z-index: 2;
  transform: translateX(-50%);
}
.mini-code h3 {
  color: #666;
}

.line {
  height: 18px;
  width: 2px;
  background-color: #999;
}

.sign-in {
  cursor: pointer;
  color: #FF0032;
}
.sign-in:hover {
  color: #333;
}
.btn-register {
  margin-left: 48px;
  padding: 0 40px;
  height: 40px;
  line-height: 40px;
  background-color: #FF0032;
  border-radius: 4px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.btn-register:hover {
  background-color: rgba(255, 0, 50, .8);
}

.user {
  position: relative;
  margin-left: 48px;
}
.icon-user {
  font-size: 22px;
}