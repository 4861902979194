.footer {
  display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.footer-inner {
	padding: 32px 0;
	/*background-color: rgba(0, 0, 0, 0.4);
	border-radius: 20px;*/
  text-align: center;
  color: #999;
}
.footer a {
	color: #999;
}